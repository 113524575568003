<template>
    <div class="add-new-csr-stamp">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.csrStampUpdate')"
            :title="$t('messages.csrStampUpdate')"
            @submitPressed="submitPressed"
        />
        <resource-form v-if="csrStamp" :resource-object="csrStamp" :resource="$CsrStamps"
                       base-url="/settings/csr-stamps"
                       create-message="messages.csrStampCreated" update-message="messages.csrStampUpdated"
                       :action="action"
                       :emails="false" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../../Settings/components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            csrStamp: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$CsrStamps.getResource({id}).then((response) => {
                this.csrStamp = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
